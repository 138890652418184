<template>
  <div class="content mt-0 mb-0 fill-height">
    <div class="header">
      <img class="Dealboard logo" src="@/assets/dealboard-logo-white.svg" />
    </div>
    <div class="page-content">
      <div class="header-content">
        <h2 class="date-head">
          {{ $t("choose_date_header") }}
        </h2>
        <p class="date-subhead">
          {{ $t("date_picker_subhead_alpha") }}
        </p>

        <p class="step">
          {{ $t("step_count", { current: 6, last: 6 }) }}
        </p>
        <v-progress-linear
          class="pa-0 mb-0 progress"
          color="#01D181"
          style="width: 950px"
          rounded
          height="6"
          :value="(6 * 100) / 6"
        ></v-progress-linear>
      </div>
      <span
        class="skip-mobile"
        @click="skipCalendly"
        data-test="Alpha:Calendly:BtnSkipCalendlyMobile"
        >{{ $t("skip_appointment") }}</span
      >
      <v-card class="pa-1 ma-0 card-calendly" light>
        <span
          class="skip-desktop"
          @click="skipCalendly"
          data-test="Alpha:Calendly:BtnSkipCalendlyDesktop"
          >{{ $t("skip_appointment") }}</span
        >
        <v-form
          @submit.stop.prevent="checkForm()"
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <div
            id="calendly-parent"
            style="height: 700px"
            class="ma-0 mb-3 mx-n7"
          >
            <div
              class="calendly-inline-widget"
              data-auto-load="false"
              data-processed="true"
            ></div>
          </div>
        </v-form>
        <v-alert class="ma-2" v-if="alert" type="error">
          {{ alert }}
        </v-alert>
      </v-card>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import { getUserLocalData } from "@/shared/helpers/authHelpers.js";
import PagesCoreAppBar from "@/views/pages/components/core/AppBar.vue";
import { LanguageEnum } from "@/shared/enums/LanguageEnum";
import { getKeyByValue } from "@/shared/helpers/objectHelper";

export default {
  name: "Calendly",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    termsOpened: false,
    terms: false,
    lazy: false,
    date: null,
    alert: null,
    error: null,
  }),
  async mounted() {
    let user = getUserLocalData();
    var calendlyUrl = process.env.VUE_APP_CALENDLY_ALPHA_URL;
    if (!calendlyUrl) {
      calendlyUrl = "https://calendly.com/rcaram/30min";
    }
    Calendly.initInlineWidget({
      url: `${calendlyUrl}?primary_color=01d281&hide_event_type_details=1&hide_landing_page_details=1`,
      parentElement: document.getElementById("calendly-parent"),
      prefill: {
        name: user.name,
        birth: user.birth,
        cpf: user.socialNumber,
        email: user.email,
      },
      utm: {},
    });
    window.addEventListener("message", (e) => {
      if (this.isCalendlyEvent(e)) {
        if (e.data.event == "calendly.event_scheduled") {
          let eventUri = e.data.payload.event.uri;
          this.scheduleFirstMeet(eventUri);
        }
      }
    });
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },
    async scheduleFirstMeet(uri) {
      this.apiService
        .postRequest("investor/schedule-first-meet", {
          CalendlyEventUri: uri,
        })
        .then((response) => {
          this.$gtag.event("schedule_calendly", {
            event_category: "investor_register",
            event_label: "user_schedule_calendly",
            value: 1,
          });
          this.$router.push("/calendly/finish");
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occurred"));
        });
    },
    getUserLang() {
      this.apiService
        .getRequest("user/language")
        .then((response) => {
          let language = getKeyByValue(LanguageEnum, response.content);
          this.$store.commit("SET_LANGUAGE", language);
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    skipCalendly() {
      this.apiService
        .postRequest("user/skip-calendly")
        .then((response) => {
          this.getUserLang();
          this.$router.push({ name: "Pipeline" });
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.$gtag.event("skip_calendly", {
        event_label: "user_skip_calendly",
        value: 0,
      });
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 100%;
  align-items: center;
}

.date-subhead {
  font-size: 18px;
  color: #9c9c9c;
  font-weight: 300;
  font-family: "Source Sans 3 Light";
}
.date-head {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.step {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.header {
  background-color: var(--dark);
  padding: 19px 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.dxa-logo {
  width: 100px;
  height: 35px;
}

.dxa-plus {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  margin-left: 10px;
  color: var(--primary);
}

.partner-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.15px;
  color: var(--white);
  margin-left: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.card-calendly {
  width: 950px;
  height: 750px;
  display: flex;
  flex-direction: column;
}

.skip-desktop {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  cursor: pointer;
  align-self: flex-end;
  margin-right: 20px;
  margin-top: 12px;
}

.skip-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .skip-desktop {
    display: none;
  }

  .skip-mobile {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.15px;
    color: var(--primary);
    align-self: flex-end;
    margin-right: 12px;
    margin-bottom: 18px;
  }

  .page-content {
    padding: 0;
  }

  .header-content {
    width: 100%;
    padding: 40px 30px 0 30px;
  }

  .date-head {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.15px;
  }

  .date-subhead {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #9c9c9c;
    margin-top: 8px;
  }

  .step {
    display: none;
  }
  .progress {
    display: none;
  }

  .card-calendly {
    padding-top: 0px !important;
    width: 90%;
    height: 50%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .card-calendly {
    padding-top: 0px !important;
    width: 95%;
    height: 70%;
    padding-bottom: 40px !important;
  }
}
</style>
