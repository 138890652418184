export const saveUserLocalData = (userObj) => {
  clearLocalStorageData();
  localStorage.setItem("user", JSON.stringify(userObj.user));
  localStorage.setItem("token", userObj.token);
  localStorage.setItem("expires", userObj.expires);
};

const clearLocalStorageData = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const getUserLocalData = () => {
  return JSON.parse(localStorage.getItem("user"));
};
